import BackendApi from "../backend-api";
import BackendConfig from "../backend-config";
import { PasswordReminderRequest } from "../models/password-reminder-request";
import { ResetPasswordRequest } from "../models/reset-password-request";


export const PasswordService = {
    sendReminder(locale: string, app_id: string, email: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = BackendConfig.Url() + `/Password/SendLink?language=${locale}`;
            let data = {
                "app_id": app_id,
                "email": email
            } as PasswordReminderRequest;

            BackendApi.post(url, data)
                .then(resp => {
                    // "temp" fix
                    if (resp?.success || resp?.isSuccess) {
                        resolve(resp);
                    } else {
                        reject(resp);
                    }
                })
                .catch(ex => reject(ex));
        });
    },
    resetPassword(access_hash: string, data: ResetPasswordRequest) {
        return new Promise((resolve, reject) => {
            let url = BackendConfig.Url() + `/Password/PasswordReset?access_hash=${access_hash}`;
            BackendApi.cleanPost(url, data)
                .then(resp => resolve(resp))
                .catch(ex => reject(ex));
        });
    },
    testResetPassword(access_hash: string) {
        return new Promise((resolve, reject) => {
            let url = BackendConfig.Url() + `/Password/PasswordReset?access_hash=${access_hash}`;
            BackendApi.cleanGet(url)
                .then(resp => resolve(resp))
                .catch(ex => reject(ex));
        });
    }
}
